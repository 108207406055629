<script lang="ts" setup>
import type { TranslateResult } from "vue-i18n";

const props = defineProps<{
  headline?: string | TranslateResult;
  image: string;
  circle?: boolean;
}>();

const sizes = "sx:100vw md:450px lg:600px xxl:948px";
</script>

<template>
  <div class="mx-n4 mx-md-0 mt-6">
    <!-- TODO: check why preload is not working -->
    <HwImage
      :src="image"
      class="d-md-none hero-image--mobile"
      :preload="false"
      :sizes="sizes"
    >
      <div class="pa-8 hero__text">
        <div v-if="headline" class="text-hero mb-4">{{ headline }}</div>

        <p class="text-large">
          <MDCSlot unwrap="p" />
        </p>
      </div>
    </HwImage>

    <v-row class="d-none d-md-flex justify-center" no-gutters>
      <v-col
        cols="6"
        style="padding-inline-start: var(--spacer)"
        class="d-flex"
      >
        <HwImage
          :aspect-ratio="circle ? 1 : 4 / 3"
          :src="image"
          :class="circle ? 'rounded-circle' : 'rounded-lg'"
          :preload="false"
          :sizes="sizes"
        />
      </v-col>

      <v-col
        class="flex-column d-none d-md-flex justify-center hw-px-small"
        cols="6"
        style="padding-inline-start: var(--spacer)"
      >
        <div v-if="headline" class="text-h1">
          {{ headline }}
        </div>

        <p class="text-hero">
          <MDCSlot unwrap="p" />
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.hero-image--mobile :deep(.v-responsive__sizer) {
  padding-bottom: unset !important;
}

.hero__text {
  background: rgba(255, 255, 255, 0.9);
  line-height: 1.5em;
}

.text-hero {
  line-height: 2em;
}
</style>
